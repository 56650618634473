<template>
  <div>
    <order-summary
      :payload="payload"
      @termsAgreed="form.termsAgreed = $event"
      @couponApplied="form.couponId = $event"
    />

    <step-controls
      :enabled="formIsValid"
      @next="$emit('next')"
      @back="$emit('back')"
    />
  </div>
</template>

<script>
export default {
  name: "ConfirmOrderStep",
  components: {
    "order-summary": () => import("@shared/cart/_confirmOrder")
  },
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        termsAgreed: this.payload.termsAgreed,
        couponId: this.payload.couponId
      }
    };
  },
  computed: {
    formIsValid() {
      if (!this.form.termsAgreed) return false;
      return true;
    }
  },
  watch: {
    "form.termsAgreed"(termsAgreed) {
      this.$emit("payload", { termsAgreed });
    },
    "form.couponId"(couponId) {
      this.$emit("payload", { couponId });
    }
  }
};
</script>
